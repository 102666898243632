/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import PDFParser from "../../../PDFParsing/index";
import { Button, Drawer, message, Spin } from "antd";
import "./AiReportViewerDrawer.css";
import { IoMdClose } from "react-icons/io";
import { getPdfData } from "../../../../services/admin-ai-viewer";
import { baseUrl, getProfile } from "../../../../services/services.profile";
import TableReportGridAdmin from "../../../ui/CustomTable/TableReportGridAdmin";
import {
  cancelTranslateData,
  saveTranslateData,
} from "../../../../services/translate";
import TranslateDrawer from "./translateDrawer/translateDrawer";
import {
  createConnectRiskAssessment,
  createNetworkRiskAssessment,
  getConnectionData,
  getNetworkData,
  getOnboardingGridData,
} from "../../../../services/customer-ai-viewer";
import { lablesMapping } from "../../../lablesMappingData";
import { getLabelByValue } from "./OCRDrawer/LanguagesData";
import { duration } from "moment";

const CustomerAiViewerDrawer = ({
  isOpen,
  setToggle,
  tokenId,
  type,
  apiId,
  fetchConnectionData,
  companyName,
}) => {
  const [buttonList, setButtonList] = useState([]);
  const [data, setData] = useState([]);
  const [response, setResponse] = useState();
  const [activeButton, setActiveButton] = useState("CDD Report");
  const [translateName, setTranslateName] = useState("");
  const [observations, setObservations] = useState("");
  const [PDF, setPdfUrl] = useState("");
  const [status, setStatus] = useState("");
  const [originalUrl, setOriginalUrl] = useState("");
  const [selectedButton, setSelectedButton] = useState("");
  const [searchText, setSearchText] = useState(null);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isOpenTranslate, setIsOpenTranslate] = useState(false);
  const [saveTranslateLoading, setSaveTranslateLoading] = useState(false);
  const [AIAssessmentloading, setAIAssessmentloading] = useState(false);
  const [clearHighlights, setClearHighlights] = useState(false);
  const [oldButtonList, setOldButtonList] = useState({
    poi: null,
    ubo: null,
  });
  const [clickedCell, setClickedCell] = useState({
    name: "",
    key: "",
    type: "",
  });
  const [originalList, setOriginalList] = useState({
    poi: [],
    ubo: [],
  });
  const [originalData, setOriginalData] = useState({
    poi: {},
    ubo: {},
  });

  const transformData = (data) => {
    const finalData = data.map((item) => ({
      key: item.key,
      name: item.categoryName,
      property: item.property,
      cddReport: item.cdd_doc.map((report, index) => ({
        id: index + 1,
        value: report,
        original: report,
      })),
      UBOCertificate: item.ubo_doc.map((ubo, index) => ({
        id: index + 1,
        value: ubo,
        original: ubo,
      })),
      companyRegistration: item.id_doc.map((reg, index) => ({
        id: index + 1,
        value: reg,
        original: reg,
      })),
      checks: item.consistency.map((check, index) => ({
        id: index + 1,
        value: check,
        original: check,
      })),
    }));
    finalData.splice(6, 0, {
      key: 15,
      name: "",
      property: "blankRow",
      cddReport: [],
      UBOCertificate: [],
      companyRegistration: [],
      checks: [],
    });
    finalData.splice(8, 0, {
      key: 16,
      name: "",
      property: "blankRow",
      cddReport: [],
      UBOCertificate: [],
      companyRegistration: [],
      checks: [],
    });
    finalData.splice(10, 0, {
      key: 17,
      name: "",
      property: "blankRow",
      cddReport: [],
      UBOCertificate: [],
      companyRegistration: [],
      checks: [],
    });

    return finalData;
  };

  const checkType = (item) => {
    if (!item) return [];
    return typeof item === "string" ? [item] : item;
  };

  const handleCleanSearch = () => {
    setSearchText("");
    setClickedCell({
      name: "",
      key: "",
      type: "",
    });
    setClearHighlights(true);
  };

  useEffect(() => {
    if (response) {
      const transformedData = transformData(response);
      setData(transformedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (!tokenId) {
      return;
    }
    setIsPdfLoading(true);
    getPdfData(tokenId)
      .then((res) => {
        if (res?.error) {
          setIsPdfLoading(false);
          return;
        }

        const CDDReport = res?.userCertificateData?.newCdd?.fileName
          ? res?.userCertificateData?.newCdd?.fileName
          : "";

        const CompanyRegistration =
          "poiOcr" in res?.userCertificateData &&
          res?.userCertificateData?.poiOcr
            ? res?.userCertificateData?.poiOcr
            : res?.userCertificateData?.newPoi?.fileName
            ? res?.userCertificateData?.newPoi?.fileName
            : "";

        const UBOCertificate =
          "uboOcr" in res?.userCertificateData &&
          res?.userCertificateData?.uboOcr
            ? res?.userCertificateData?.uboOcr
            : res?.userCertificateData?.newUbo?.fileName
            ? res?.userCertificateData?.newUbo?.fileName
            : "";

        setButtonList([
          {
            name: "CDD Report",
            fileName: CDDReport,
          },
          {
            name: "Company Registration",
            fileName: CompanyRegistration,
          },
          {
            name: "UBO Certificate",
            fileName: UBOCertificate,
          },
        ]);

        setPdfUrl(
          res?.userCertificateData?.newCdd?.fileName
            ? res?.userCertificateData?.newCdd?.fileName
            : ""
        );

        // Check if translatedPoi key exists and it's not empty, then filter
        const filteredTranslatedPoi =
          res.userCertificateData.translatedPoi &&
          res.userCertificateData.translatedPoi.length > 0
            ? res.userCertificateData.translatedPoi.filter(
                (item) => item.showUser
              )
            : [];

        // Check if translatedUbo key exists and it's not empty, then filter
        const filteredTranslatedUbo =
          res.userCertificateData.translatedUbo &&
          res.userCertificateData.translatedUbo.length > 0
            ? res.userCertificateData.translatedUbo.filter(
                (item) => item.showUser
              )
            : [];

        setOriginalList({
          poi: filteredTranslatedPoi,
          ubo: filteredTranslatedUbo,
        });

        getGridResponse();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPdfLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenId]);

  const dataSet = (res) => {
    const dataset = Object.keys(lablesMapping).map((key, index) => {
      const data = res[key] || {};
      return {
        key: index + 1,
        property: key,
        categoryName: lablesMapping[key],
        cdd_doc: checkType(data.cdd_doc || "N/A"),
        id_doc: checkType(data.id_doc || "N/A"),
        ubo_doc: checkType(data.ubo_doc || "N/A"),
        consistency: checkType(data.consistency || "N/A"),
      };
    });
    setObservations(res["brief_risk_assessment"]);

    setResponse(dataset);
  };

  const getGridResponse = () => {
    if (!tokenId) {
      return;
    }

    setIsTableLoading(true);
    handleCleanSearch();
    getOnboardingGridData(tokenId)
      .then((res) => {
        if (res?.error) {
          setIsTableLoading(false);
          return;
        }
        dataSet(res.gridData.user_edited);
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };

  const handleCancelTranslation = () => {
    if (!tokenId) {
      return;
    }

    const translatedData =
      selectedButton === "poi"
        ? oldButtonList.poi
        : selectedButton === "ubo" && oldButtonList.ubo;

    const originalSelectedViewList =
      selectedButton === "poi"
        ? originalList.poi
        : selectedButton === "ubo" && originalList.ubo;

    const ignoreCallCancelAPI =
      !!originalSelectedViewList.find(
        (x) => x.showUser && x.translatedTo === translatedData?.translatedTo
      ) || translatedData?.showAdmin;

    (ignoreCallCancelAPI
      ? Promise.resolve()
      : cancelTranslateData({
          pdfName: translatedData?.translatedDoc,
        })
    ).then(() => {
      setPdfUrl(originalUrl);
      setOldButtonList((prevState) => ({
        ...prevState,
        [selectedButton]: null,
      }));

      setOriginalList((prevState) => ({
        ...prevState,
        [selectedButton]: prevState[selectedButton].slice(
          0,
          prevState[selectedButton].length - 1
        ),
      }));

      setOriginalData((prevState) => ({
        ...prevState,
        [selectedButton]: {},
      }));
    });
  };

  const handleSaveTranslation = () => {
    if (!tokenId) {
      return;
    }

    setSaveTranslateLoading(true);

    const translatedDocData =
      selectedButton === "poi"
        ? originalData.poi
        : selectedButton === "ubo" && originalData.ubo;

    const payload = {
      token_id: tokenId,
      docType: selectedButton,
      translatedFrom: translatedDocData.translatedFrom,
      translatedTo: translatedDocData.translatedTo,
      originalDoc: translatedDocData.originalDoc,
      translatedDoc: translatedDocData.translatedDoc,
    };

    if (type === "connection") {
      payload["reqConnectionId"] = apiId;
    } else {
      payload["networkId"] = apiId;
    }

    saveTranslateData(payload)
      .then((res) => {
        if (res?.error) {
          setSaveTranslateLoading(false);
          return message.error("Translation saving failed, please try again");
        }
        message.success(
          "The translated document has been added to the Supporting Documents in the main Risk Assessment screen"
        );
        fetchConnectionData();
        setOldButtonList((prevState) => ({
          ...prevState,
          [selectedButton]: null,
        }));
      })
      .catch((error) => {
        console.log(error);
        message.error("Translation saving failed, please try again");
      })
      .finally(() => {
        setSaveTranslateLoading(false);
      });
  };

  const signalRef = useRef(null);

  const createAiRiskAssessment = (language) => {
    signalRef.current = new AbortController();
    const signal = signalRef.current.signal;

    setAIAssessmentloading(true);

    getProfile().then((user) => {
      if (
        user?.riskAssessmentLimit <= 0 ||
        user?.riskAssessmentLimit === null
      ) {
        setAIAssessmentloading(false);
        return message.info(
          "You've reached the limit of generating reports per month, please try later."
        );
      } else {
        apiCall();
      }
    });

    const apiCall = () => {
      const payload = {
        ...(type === "connection" && {
          reqConnectionId: apiId,
          lang: language,
        }),
        ...(type === "network" && {
          networkId: apiId,
          lang: language,
        }),
      };

      (type === "connection"
        ? createConnectRiskAssessment(payload, signal)
        : createNetworkRiskAssessment(payload, signal)
      )
        .then((res) => {
          if (res?.error) {
            setAIAssessmentloading(false);
            return message.error(
              "Your Create AI Risk Assessment Report request failed due to something went wrong on the server side, please try again.",
              duration(2)
            );
          }
          setStatus("success");
          message.success(
            "Requested AI Risk Assessment Report generated successfully"
          );
          // Fetch Connection Details
          fetchConnectionData();
        })
        .finally(() => {
          setAIAssessmentloading(false);
        });
    };
  };

  const intervalRef = useRef(null);

  const handleClose = () => {
    setToggle(false);
  };

  const fetchConnectionApi = (signal) => {
    getConnectionData(apiId, signal).then((res) => {
      if (res?.error) {
        clearInterval(intervalRef.current);
        setAIAssessmentloading(false);
        return;
      } else if (
        res.requestConnectionData.riskAssessReportStatus === "failure"
      ) {
        clearInterval(intervalRef.current);
        setAIAssessmentloading(false);
        return message.error(
          "Your Create AI Risk Assessment Report request failed due to something went wrong on the server side, please try again.",
          duration(2)
        );
      } else if (
        res.requestConnectionData.riskAssessReportStatus === "success"
      ) {
        setStatus("success");
        clearInterval(intervalRef.current);
        setAIAssessmentloading(false);
        fetchConnectionData();
      } else if (
        res.requestConnectionData.riskAssessReportStatus === "" ||
        !res.requestConnectionData.riskAssessReportStatus
      ) {
        clearInterval(intervalRef.current);
        setAIAssessmentloading(false);
      }
    });
  };

  const fetchNetworkApi = () => {
    getNetworkData(apiId).then((res) => {
      if (res?.error) {
        clearInterval(intervalRef.current);
        setAIAssessmentloading(false);
        return;
      } else if (res.usersNetworkData.riskAssessReportStatus === "failure") {
        clearInterval(intervalRef.current);
        setAIAssessmentloading(false);
        return message.error(
          "Your Create AI Risk Assessment Report request failed due to something went wrong on the server side, please try again.",
          duration(2)
        );
      } else if (res.usersNetworkData.riskAssessReportStatus === "success") {
        setStatus("success");
        clearInterval(intervalRef.current);
        setAIAssessmentloading(false);

        // fetch connection details
        fetchConnectionData();
      } else if (
        res.usersNetworkData.riskAssessReportStatus === "" ||
        !res.usersNetworkData.riskAssessReportStatus
      ) {
        clearInterval(intervalRef.current);
        setAIAssessmentloading(false);
      }
    });
  };

  useEffect(() => {
    if (isOpen) {
      intervalRef.current = setInterval(
        type === "connection" ? fetchConnectionApi : fetchNetworkApi,
        5000
      ); // Start polling every 20 seconds
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setAIAssessmentloading(true);
    type === "connection" ? fetchConnectionApi() : fetchNetworkApi();
    return () => {
      signalRef.current?.abort(); // Abort request when component unmounts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <>
        <Drawer
          placement="right"
          className="AiReportViewerDrawer select-none"
          closable={false}
          onClose={handleClose}
          open={isOpen}
        >
          <div
            className="flex lg:hidden justify-end cursor-pointer pr-4 pt-4 pb-2 z-30 sticky top-0 bg-white"
            onClick={() => {
              handleClose();
            }}
          >
            <IoMdClose
              size={24}
              className="text-gray-500  hover:text-primary flex justify-center items-center "
            />
          </div>
          <div className="flex lg:flex-row-reverse flex-col lg:h-full h-[calc(100vh-48px)] overflow-auto ">
            <div className="flex lg:w-[60%] w-full flex-col lg:ml-4">
              {!isPdfLoading && (
                <>
                  <div
                    className="lg:flex hidden justify-end cursor-pointer pr-4 pt-4 mb-2 sticky top-0 bg-white"
                    onClick={handleClose}
                  >
                    <IoMdClose
                      size={24}
                      className="text-gray-500  hover:text-primary flex justify-center items-center"
                    />
                  </div>

                  <div className="flex flex-col lg:gap-6 gap-4 mt-2 lg:h-[calc(100vh-47px)] lg:overflow-y-auto pr-4 pl-4 lg:pl-0 pb-4">
                    <div className="flex w-full flex-wrap-reverse gap-4">
                      <div className="flex xl:gap-4 gap-2">
                        {activeButton !== "CDD Report" && (
                          <>
                            {((activeButton === "Company Registration" &&
                              oldButtonList.poi) ||
                              (activeButton === "UBO Certificate" &&
                                oldButtonList.ubo)) && (
                              <>
                                <Button
                                  loading={saveTranslateLoading}
                                  onClick={() => handleSaveTranslation()}
                                  className="text-xs flex !border-[#ffc000] border-2 whitespace-nowrap font-semibold tracking-wide !text-white !bg-[#ffc000] px-4 h-8 rounded-lg"
                                >
                                  Save translation
                                </Button>

                                <Button
                                  onClick={() => {
                                    handleCancelTranslation();
                                  }}
                                  className="flex text-xs !border-primary border-2 whitespace-nowrap font-semibold tracking-wide !text-primary !bg-white px-4 h-8 rounded-lg"
                                >
                                  Cancel
                                </Button>
                              </>
                            )}

                            {((activeButton === "Company Registration" &&
                              !oldButtonList.poi) ||
                              (activeButton === "UBO Certificate" &&
                                !oldButtonList.ubo)) && (
                              <Button
                                onClick={() => {
                                  setIsOpenTranslate(true);
                                  setTranslateName(activeButton);
                                }}
                                type="primary"
                                className="flex text-xs border-2 whitespace-nowrap font-semibold tracking-wide !text-white px-4 h-8 rounded-lg"
                              >
                                Translate
                              </Button>
                            )}
                          </>
                        )}
                      </div>

                      <div className="flex xl:gap-4 gap-2 justify-end ml-auto">
                        {buttonList.map((item, index) => (
                          <button
                            type="button"
                            key={index}
                            onClick={() => {
                              console.log(originalData);
                              console.log(
                                "PDF URL",
                                Object.keys(
                                  item.name === "Company Registration"
                                    ? originalData.poi
                                    : item.name === "UBO Certificate" &&
                                        originalData.ubo
                                ).length > 0
                                  ? item.name === "Company Registration"
                                    ? originalData.poi.translatedDoc
                                    : item.name === "UBO Certificate" &&
                                      originalData.ubo.translatedDoc
                                  : item.fileName
                              );
                              setPdfUrl(
                                Object.keys(
                                  item.name === "Company Registration"
                                    ? originalData.poi
                                    : item.name === "UBO Certificate" &&
                                        originalData.ubo
                                ).length > 0
                                  ? item.name === "Company Registration"
                                    ? originalData.poi.translatedDoc
                                    : item.name === "UBO Certificate" &&
                                      originalData.ubo.translatedDoc
                                  : item.fileName
                              );
                              setOriginalUrl(item.fileName);
                              handleCleanSearch();
                              setActiveButton(item.name);
                              setSelectedButton(
                                item.name === "Company Registration"
                                  ? "poi"
                                  : item.name === "UBO Certificate" && "ubo"
                              );
                            }}
                            className={`text-xs border-primary border-2 whitespace-nowrap font-semibold tracking-wide px-4 h-8 rounded-lg ${
                              activeButton === item.name
                                ? "text-primary bg-white"
                                : "text-white bg-primary"
                            }`}
                          >
                            {item.name}
                          </button>
                        ))}
                      </div>
                    </div>
                    <TableReportGridAdmin
                      setSearchText={setSearchText}
                      PDF={baseUrl + "/" + PDF}
                      isTableLoading={isTableLoading}
                      data={data}
                      setData={setData}
                      setClickedCell={setClickedCell}
                      clickedCell={clickedCell}
                      handleCleanSearch={handleCleanSearch}
                    />

                    {observations && (
                      <div>
                        <span className="font-bold">Observations:</span>{" "}
                        {observations}
                      </div>
                    )}

                    <div className="flex flex-wrap justify-end  xl:gap-4 gap-2 mt-auto">
                      <Button
                        onClick={() => {
                          setIsOpenTranslate(true);
                          setTranslateName("Risk Assessment Report");
                        }}
                        loading={AIAssessmentloading}
                        className={`text-xs ${
                          status === "success"
                            ? "!border-[#ffc100]  !bg-[#ffc100]"
                            : "!border-primary !bg-primary"
                        } flex cursor-pointer border-2 whitespace-nowrap font-semibold tracking-wide !text-white  px-4 h-8 rounded-lg`}
                      >
                        Create AI Risk Assessment Report
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="lg:w-[40%] w-full flex flex-col pl-4 pr-4 lg:pr-0 py-4">
              <div className="text text-center font-semibold mb-2 flex flex-col h-full">
                {activeButton} for {companyName}
                {((selectedButton === "poi" && originalList?.poi.length > 0) ||
                  (selectedButton === "ubo" &&
                    originalList?.ubo.length > 0)) && (
                  <div className="flex flex-col justify-start items-center">
                    {Object.keys(
                      selectedButton === "poi"
                        ? originalData.poi
                        : selectedButton === "ubo" && originalData.ubo
                    ).length > 0 && (
                      <div>
                        <span>
                          AI Translated from{" "}
                          {selectedButton === "poi"
                            ? originalData.poi.translatedFrom
                            : selectedButton === "ubo" &&
                              originalData.ubo.translatedFrom}{" "}
                          into{" "}
                          {selectedButton === "poi"
                            ? getLabelByValue(originalData.poi.translatedTo)
                            : selectedButton === "ubo" &&
                              getLabelByValue(
                                originalData.ubo.translatedTo
                              )}{" "}
                        </span>
                        <span className="mx-1">-</span>
                        <a
                          className="!text-blue-600"
                          onClick={() => {
                            setPdfUrl(
                              selectedButton === "poi"
                                ? originalData.poi.originalDoc
                                : selectedButton === "ubo" &&
                                    originalData.ubo.originalDoc
                            );
                            setOriginalData((prevState) => ({
                              ...prevState,
                              [selectedButton]: {},
                            }));
                          }}
                        >
                          Show original document
                        </a>
                      </div>
                    )}

                    {Object.keys(
                      selectedButton === "poi"
                        ? originalData.poi
                        : selectedButton === "ubo" && originalData.ubo
                    ).length === 0 && (
                      <div>
                        <span>Show the document translated in</span>
                        <span className="mx-1">:</span>

                        {(selectedButton === "poi"
                          ? originalList?.poi || []
                          : selectedButton === "ubo"
                          ? originalList?.ubo || []
                          : []
                        ).map((value, index) => (
                          <a
                            key={index}
                            className="!text-blue-600 ml-1"
                            onClick={() => {
                              setOriginalData((prevState) => ({
                                ...prevState,
                                [selectedButton]: value,
                              }));
                              setPdfUrl(value.translatedDoc);
                            }}
                          >
                            {getLabelByValue(value.translatedTo)}
                            {(selectedButton === "poi"
                              ? originalList?.poi || []
                              : selectedButton === "ubo"
                              ? originalList?.ubo || []
                              : []
                            ).length -
                              1 ===
                            index
                              ? ""
                              : ", "}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {isPdfLoading ? (
                  <Spin className="mt-20" />
                ) : (
                  <PDFParser
                    PDFUrl={baseUrl + "/" + PDF}
                    searchText={searchText}
                    clearHighlights={clearHighlights}
                    setClearHighlights={setClearHighlights}
                  />
                )}
              </div>
            </div>
          </div>
        </Drawer>
      </>
      {isOpenTranslate && (
        <TranslateDrawer
          isOpenTranslate={isOpenTranslate}
          handleClose={() => setIsOpenTranslate(false)}
          translateName={translateName}
          createAiRiskAssessment={createAiRiskAssessment}
          tokenId={tokenId}
          setOldButtonList={setOldButtonList}
          originalUrl={originalUrl}
          setOriginalList={setOriginalList}
          setOriginalData={setOriginalData}
          setPdfUrl={setPdfUrl}
        />
      )}
    </>
  );
};

export default CustomerAiViewerDrawer;

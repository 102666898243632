import axios from "axios";
import { baseUrl } from "./services.profile";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true;

export const createConnectRiskAssessment = async (payload, signal) => {
  const response = await axios.post(
    baseUrl + `/api/connect/create-risk-assessment`,
    payload,
    { signal }
  );
  return response.data;
};
export const createNetworkRiskAssessment = async (payload, signal) => {
  const response = await axios.post(
    baseUrl + `/api/network/create-risk-assessment`,
    payload,
    { signal }
  );
  return response.data;
};

export const getConnectionData = async (payload) => {
  const response = await axios.get(
    baseUrl + `/api/connect/get-req-connection?reqConnectionId=${payload}`
  );
  return response.data;
};

export const getNetworkData = async (payload) => {
  const response = await axios.get(
    baseUrl + `/api/network/get-user-network?userNetworkId=${payload}`
  );
  return response.data;
};

export const getOnboardingGridData = async (payload) => {
  const response = await axios.get(
    baseUrl + `/api/onboarding/get-grid-data?tokenId=${payload}`
  );
  return response.data;
};
